import _ from "lodash";
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from "react-router";

import { Box, Button, CircularProgress, Container, MobileStepper } from '@mui/material';
import { CheckboxComponent, DoneComponent, RadioComponent, RatingComponent, TextFieldComponent } from './components';

import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

const Home = () => {

    const location = useLocation();

    const query = new URLSearchParams(location.search);
    const reward_value = query.get('reward');
    const i_customer = query.get('i_customer');

    const { survey_id } = useParams();
    const [activeStep, setActiveStep] = useState(0);
    const [disabledStep, setDisabledStep] = useState(true);
    const [steps, setSteps] = useState(0);
    const [survey, setSurvey] = useState([]);
    const [survey_answers, setSurveyAnswers] = useState([]);
    const [surveys, setSurveys] = useState([
        {
            id: 1,
            survey: [
                {
                    number: 1,
                    type: 'rating',
                    type_config: {
                        format: 'numerical',
                        rate_amount: 5,
                    },
                    question: 'How would you rate your onboarding experience with us:'
                },
                {
                    number: 2,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Yes', 'No'],
                    },
                    conditional: true,
                    conditional_config: {
                        condition: 'No',
                        number: 2.1,
                        response_question: 'Why did our megstraders not meet your expectations?',
                        type: 'textfield',
                    },
                    question: 'Did our megstraders meet your expectations?'
                },
                {
                    number: 3,
                    type: 'radio',
                    type_config: {
                        radio_options: ['The non-expiry airtime and data', 'The price', 'The megstraders were friendly and helpful', 'The FREE data that I get every day', 'All of the above'],
                    },
                    conditional: false,
                    question: 'What convinced you to buy a megsApp SIM card?'
                },
                {
                    number: 4,
                    type: 'rating',
                    type_config: {
                        format: 'satisfaction',
                        rate_amount: 5,
                    },
                    question: 'Have you been enjoying our App thus far?'
                },
                {
                    number: 5,
                    type: 'rating',
                    type_config: {
                        format: 'numerical',
                        rate_amount: 5,
                    },
                    question: 'How likely would you recommend megsApp to a friend or family member?'
                },
                {
                    number: 6,
                    type: 'radio',
                    type_config: {
                        radio_options: ['No - Everything is great', 'Yes'],
                    },
                    conditional: true,
                    conditional_config: {
                        condition: 'Yes',
                        number: 6.1,
                        response_question: 'Tell us what we can improve on:',
                        type: 'textfield',
                    },
                    question: 'Is there anything we could improve on?'
                },
                {
                    number: 7,
                    type: 'done',
                },
            ]
        },
        {
            id: 2,
            survey: [
                {
                    number: 1,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Yes, every day', 'Sometimes', 'Not yet'],
                    },
                    conditional: false,
                    question: 'Have you been watching ads in-App?'
                },
                {
                    number: 2,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Yes a lot', "It's okay", 'Not at all'],
                    },
                    conditional: true,
                    conditional_config: {
                        condition: 'Not at all',
                        number: 2.1,
                        response_question: 'What about watching ads in-App do you dislike?',
                        type: 'textfield',
                    },
                    question: 'Do you enjoy watching our ads in-App?'
                },
                {
                    number: 3,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Yes', 'No'],
                    },
                    conditional: false,
                    question: 'Do you think we reward you enough for watching ads in-App?'
                },
                {
                    number: 4,
                    type: 'textfield',
                    conditional: false,
                    question: 'Which brands would you like to see advertising in megsApp?'
                },
                {
                    number: 5,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Bigger rewards', 'More brands that advertise', 'More interactive ads like games'],
                    },
                    conditional: false,
                    question: 'What would make you watch more ads for FREE data?'
                },
                {
                    number: 6,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Yes', 'No'],
                    },
                    conditional: true,
                    conditional_config: {
                        condition: 'No',
                        number: 6.1,
                        response_question: "Why won't you recommend megsApp to a friend?",
                        type: 'textfield',
                    },
                    question: 'Would you recommend megsApp to a friend?'
                },
                {
                    number: 7,
                    type: 'done',
                },
            ]
        },
        {
            id: 3,
            survey: [
                {
                    number: 1,
                    type: 'rating',
                    type_config: {
                        format: 'numerical',
                        rate_amount: 5,
                    },
                    question: 'Rate your overall experience with megsApp'
                },
                {
                    number: 2,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Yes - most definitely', "It's okay", 'Not at all'],
                    },
                    conditional: false,
                    question: 'Do you find megsApp easy to use?'
                },
                {
                    number: 3,
                    type: 'radio',
                    type_config: {
                        radio_options: ['The simple to use App', 'The FREE data rewards', 'The top up feature in-App (With bonus 10%)', 'The overall look and feel', 'The service', 'Price', 'Other'],
                    },
                    conditional: false,
                    question: 'What do you like the most about megsApp?'
                },
                {
                    number: 4,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Yes', 'No'],
                    },
                    conditional: true,
                    conditional_config: {
                        condition: 'No',
                        number: 4.1,
                        response_question: 'Why did you find it difficult to top up in-App?',
                        type: 'textfield',
                    },
                    question: 'Did you find it easy to top up your account in-App?'
                },
                {
                    number: 5,
                    type: 'radio',
                    type_config: {
                        radio_options: ['No issues', 'Yes'],
                    },
                    conditional: true,
                    conditional_config: {
                        condition: 'Yes',
                        number: 5.1,
                        response_question: 'What issues have you experienced?',
                        type: 'textfield',
                    },
                    question: 'Have you experienced any issues with megsApp?'
                },
                {
                    number: 6,
                    type: 'radio',
                    type_config: {
                        radio_options: ['No - Everything works great!', 'Yes'],
                    },
                    conditional: true,
                    conditional_config: {
                        condition: 'Yes',
                        number: 6.1,
                        response_question: 'Tell us what we can improve on:',
                        type: 'textfield',
                    },
                    question: 'Is there anything we could improve on?'
                },
                {
                    number: 7,
                    type: 'done',
                },
            ]
        },
        {
            id: 4,
            survey: [
                {
                    number: 1,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Female', 'Male', 'Other', 'Prefer not to say'],
                    },
                    conditional: false,
                    question: 'What is your gender?'
                },
                {
                    number: 2,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Single', 'Married', 'Seperated or Divorced', 'In a serious relationship', 'Casual dating', 'Widowed'],
                    },
                    conditional: false,
                    question: 'What is your relationship status?'
                },
                {
                    number: 3,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Student', 'Working - Full time', 'Working - Part time', 'Working - Management level or higher', 'Have my own business', 'Stay at home parent', 'Looking for a job'],
                    },
                    conditional: false,
                    question: 'What is your occupation?'
                },
                {
                    number: 4,
                    type: 'radio',
                    type_config: {
                        radio_options: ['0-R3500', 'R3500-R8500', 'R8500-R15000', 'R15000-R25000', 'R25000+'],
                    },
                    conditional: false,
                    question: 'How much do earn per month in Rands?'
                },
                {
                    number: 5,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Yes', 'No'],
                    },
                    conditional: false,
                    question: 'Do you have your own car?'
                },
                {
                    number: 6,
                    type: 'checkbox',
                    type_config: {
                        checkbox_options: ['Online streaming', "Online betting/gambling (if you're 18+)", 'Online gaming', 'Online shopping'],
                    },
                    conditional: false,
                    question: 'Which of these online services do you use?'
                },
                {
                    number: 7,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Yes - defintely', 'Maybe', 'No'],
                    },
                    conditional: false,
                    question: 'Would you buy more of a specific product if we rewarded you with free data for buying it?'
                },
                {
                    number: 8,
                    type: 'textfield',
                    conditional: false,
                    question: 'What are your hobbies?'
                },
                {
                    number: 9,
                    type: 'checkbox',
                    type_config: {
                        checkbox_options: ['Chocolates', 'Peanut Butter', 'Sodas', 'Fast food', 'Fruits', 'Health Supplements', 'Sneakers'],
                    },
                    conditional: false,
                    question: 'Which one of these products do you purchase regularly?'
                },
                {
                    number: 10,
                    type: 'radio',
                    type_config: {
                        radio_options: ['KFC', 'McDonalds', 'Romans Pizza', 'Chicken Licken', 'Nandos', 'Debonairs', 'Steers', 'The Fish & Chip Co'],
                    },
                    conditional: false,
                    question: 'What is your favourite fast food outlet?'
                },
                {
                    number: 11,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Spar', 'Checkers', 'Woolworths', "Pick 'n Pay", 'Choppies'],
                    },
                    conditional: false,
                    question: 'Where do you buy your groceries?'
                },
                {
                    number: 12,
                    type: 'done',
                },
            ]
        },
        {
            id: 5,
            survey: [
                {
                    number: 1,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Social Media', 'Friend/Family member', 'Website', 'a megstrader approached me'],
                    },
                    conditional: false,
                    question: 'Where did you find out about us?'
                },
                {
                    number: 2,
                    type: 'radio',
                    type_config: {
                        radio_options: ['megsApp', 'MTN', 'Vodacom', 'Cell C', 'FNB Connect', 'Other'],
                    },
                    conditional: true,
                    conditional_config: {
                        condition: 'MTN Vodacom Cell C FNB Connect Other',
                        number: 2.1,
                        response_question: 'What would be required from us to become your primary mobile service provider?',
                        type: 'textfield',
                    },
                    question: 'Who is your primary mobile service provider?'
                },
                {
                    number: 3,
                    type: 'textfield',
                    conditional: false,
                    question: 'Describe what is required from us to become your primary mobile service provider?'
                },
                {
                    number: 4,
                    type: 'done',
                },
            ]
        },
        {
            id: 6,
            survey: [
                {
                    number: 1,
                    type: 'rating',
                    type_config: {
                        format: 'numerical',
                        rate_amount: 5,
                    },
                    question: 'Please rate the MiSiM App'
                },
                {
                    number: 2,
                    type: 'textfield',
                    conditional: false,
                    question: 'What can we do better?'
                },
                {
                    number: 3,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Yes', 'No'],
                    },
                    conditional: false,
                    question: 'Have you tried our wallet in the MiSiM App?'
                },
                {
                    number: 4,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Yes', 'No'],
                    },
                    conditional: false,
                    question: 'Would you buy pre-paid electricity through the MiSiM App?'
                },
                {
                    number: 5,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Yes', 'No'],
                    },
                    conditional: false,
                    question: 'Did you know that you can Transfer Data to a friend on the MiSiM App?'
                },
                {
                    number: 6,
                    type: 'done',
                }
            ]
        },
        {
            id: 7,
            survey: [
                {
                    number: 1,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Yes', 'No'],
                    },
                    conditional: true,
                    conditional_config: {
                        condition: 'No',
                        number: 1.1,
                        response_question: 'Please specify what we can improve:',
                        type: 'textfield',
                    },
                    question: 'Do you like the current data bundles that we offer?'
                },
                {
                    number: 2,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Yes', 'No'],
                    },
                    conditional: true,
                    conditional_config: {
                        condition: 'No',
                        number: 2.1,
                        response_question: 'Please specify the other network provider(s) you use:',
                        type: 'textfield',
                    },
                    question: 'Is megsApp the only SIM card you are currently using?'
                },
                {
                    number: 3,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Lower prices', 'Better network coverage', 'Larger data bundles', 'More attractive promotions', 'Better customer service', 'Other'],
                    },
                    conditional: true,
                    conditional_config: {
                        condition: 'Other',
                        number: 3.1,
                        response_question: 'Please specify:',
                        type: 'textfield',
                    },
                    question: 'What would motivate you to use us as your primary SIM card?'
                },
                {
                    number: 4,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Non-expiring', 'Expiring'],
                    },
                    conditional: true,
                    conditional_config: {
                        condition: 'Non-expiring Expiring',
                        number: 4.1,
                        response_question: 'Please share why you prefer this option:',
                        type: 'textfield',
                    },
                    question: 'Do you prefer expiring or non-expiring data bundles?'
                },
                {
                    number: 5,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Yes', 'No'],
                    },
                    conditional: false,
                    question: 'Would you be interested in unlimited voice call rate packages?'
                },
                {
                    number: 6,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Yes', 'No'],
                    },
                    conditional: false,
                    question: 'Do you think we should offer larger data bundles (e.g., 100GB for R999 - non-expiring)'
                },
                {
                    number: 7,
                    type: 'rating',
                    type_config: {
                        format: 'numerical',
                        rate_amount: 5,
                    },
                    conditional_config: {
                        condition: 'Yes',
                        number: 7.1,
                        response_question: 'Please share any additional comments or suggestions you have for us:',
                        type: 'textfield',
                    },
                    question: 'How satisfied are you with our overall service?'
                },
                {
                    number: 8,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Through the app', 'Via USSD codes', 'Other'],
                    },
                    conditional: false,
                    question: 'How do you typically purchase your data bundles?'
                },
                {
                    number: 9,
                    type: 'radio',
                    type_config: {
                        radio_options: ['R50-R150', 'R150-R300', 'R300-R450', 'R450-R850', 'R850-R1500', 'R1500+'],
                    },
                    conditional: false,
                    question: 'What is your total data/airtime spend per month on our network and others?'

                },
                {
                    number: 10,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Daily', 'Weekly', 'Every 2 weeks', 'Once a month', 'Other'],
                    },
                    conditional: false,
                    question: 'How often do you buy data?'
                },
                {
                    number: 11,
                    type: 'done',
                },
            ]
        },
        {
            id: 8,
            survey: [
                {
                    number: 1,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Yes', 'No'],
                    },
                    conditional: true,
                    conditional_config: {
                        condition: 'No',
                        number: 1.1,
                        response_question: 'Please specify what we can improve:',
                        type: 'textfield',
                    },
                    question: 'Is this your primary SIM card?'
                },
                {
                    number: 2,
                    type: 'textfield',
                    conditional: false,
                    question: 'Which province do you live in?'
                },
                {
                    number: 3,
                    type: 'textfield',
                    conditional: false,
                    question: 'Which city or town do you live in?'
                },
                {
                    number: 4,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Under 25', '25-35', '35-50', 'Over 50'],
                    },
                    conditional: false,
                    question: 'What is your age group?'
                },
                {
                    number: 5,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Daily', 'Weekly', 'Monthly', 'Rarely'],
                    },
                    conditional: false,
                    question: 'How often do you use data or airtime on this SIM card?'
                },
                {
                    number: 6,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Yes', 'No'],
                    },
                    conditional: false,
                    question: 'Would you like access to bundles customised to your usage and budget?'
                },
                {
                    number: 7,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Yes', 'No'],
                    },
                    conditional: false,
                    question: 'Would you be interested in bundles available for specific times of the day (e.g., nighttime bundles)?'
                },
                {
                    number: 8,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Yes', 'No'],
                    },
                    conditional: false,
                    question: 'Would you consider buying larger bundles if they were available at a discount (e.g., 55GB, 100GB, 150GB)?'
                },
                {
                    number: 9,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Yes', 'No'],
                    },
                    conditional: false,
                    question: 'Would you be interested in voice bundles (e.g., 50min, 100min, 150min, 200min)?'
                },
                {
                    number: 10,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Daily', 'Weekly', 'Monthly', 'Rarely', 'Never'],
                    },
                    conditional: false,
                    question: 'How often do you use the internet on your phone for the following activities? Streaming videos, Social media, Messaging and calls, Gaming Online shopping, Work or study-related activities.'
                },
                {
                    number: 11,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Yes, I work in a specific industry (please specify)', 'Yes, I receive a government grant', 'No'],
                    },
                    conditional: false,
                    question: 'Do you work in a specific industry or receive a government grant?'
                },
                {
                    number: 12,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Daily', 'Weekly', 'Fortnightly', 'Monthly', 'Other'],
                    },
                    conditional: true,
                    conditional_config: {
                        condition: 'Other',
                        number: 12.1,
                        response_question: 'Please specify:',
                        type: 'textfield',
                    },
                    question: 'How often do you receive your salary?'
                },
                {
                    number: 13,
                    type: 'radio',
                    type_config: {
                        radio_options: ['No formal education', 'High School', 'Diploma', 'Degree', 'Postgraduate', 'Other'],
                    },
                    conditional: true,
                    conditional_config: {
                        condition: 'Other',
                        number: 13.1,
                        response_question: 'Please specify:',
                        type: 'textfield',
                    },
                    question: 'What is your highest level of education?'
                },
                {
                    number: 14,
                    type: 'radio',
                    type_config: {
                        radio_options: ['15th', '20th', '25th', 'End of the month', 'Other'],
                    },
                    conditional: true,
                    conditional_config: {
                        condition: 'Other',
                        number: 14.1,
                        response_question: 'Please specify:',
                        type: 'textfield',
                    },
                    question: 'On which day of the month do you usually receive your income?'
                },
                {
                    number: 15,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Under R5,000', 'R5,000 - R10,000', 'R10,000 - R15,000', 'R15,000 - R20,000', 'Over R20,000'],
                    },
                    conditional: false,
                    question: 'What is your monthly income range?'
                },
                {
                    number: 16,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Free data for using a certain amount of data', 'Discounted data bundles for specific actions (e.g., watching a video, answering questions)', 'Competitions with prizes (cash, data, etc.)', 'Other'],
                    },
                    conditional: true,
                    conditional_config: {
                        condition: 'Other',
                        number: 16.1,
                        response_question: 'Please specify:',
                        type: 'textfield',
                    },
                    question: 'Which types of promotions or incentives are you most interested in?'
                },
                {
                    number: 17,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Yes', 'No'],
                    },
                    conditional: false,
                    question: 'Would you like to receive special offers on your birthday?'
                },
                {
                    number: 18,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Family or shared data plans', 'Unlimited streaming bundles', 'Roaming options at affordable rates', 'Payment options for subscriptions (e.g., Netflix, Spotify)', 'Other'],
                    },
                    conditional: true,
                    conditional_config: {
                        condition: 'Other',
                        number: 18.1,
                        response_question: 'Please specify:',
                        type: 'textfield',
                    },
                    question: 'Which of the following features would you be interested in using?'
                },
                {
                    number: 19,
                    type: 'textfield',
                    conditional: false,
                    question: 'Any additional comments or suggestions to help us improve?'
                },
                {
                    number: 20,
                    type: 'done',
                },
            ]
        },
        {
            id: 9,
            survey: [
                {
                    number: 1,
                    type: 'rating',
                    type_config: {
                        format: 'numerical',
                        rate_amount: 5,
                    },
                    question: 'Overall, how would you rate STADIO Connect?'
                },
                {
                    number: 2,
                    type: 'rating',
                    type_config: {
                        format: 'numerical',
                        rate_amount: 5,
                    },
                    question: 'How easy was it to activate your eSIM?'
                },
                {
                    number: 3,
                    type: 'rating',
                    type_config: {
                        format: 'numerical',
                        rate_amount: 5,
                    },
                    question: 'How easy was it to find, download, setup, and access the STADIO Connect app?'
                },
                {
                    number: 4,
                    type: 'rating',
                    type_config: {
                        format: 'numerical',
                        rate_amount: 5,
                    },
                    question: 'How would you rate your experience with the App set up?'
                },
                {
                    number: 5,
                    type: 'rating',
                    type_config: {
                        format: 'numerical',
                        rate_amount: 5,
                    },
                    question: 'How would you rate your experience with the App navigation?'
                },
                {
                    number: 6,
                    type: 'rating',
                    type_config: {
                        format: 'numerical',
                        rate_amount: 5,
                    },
                    question: 'How would you rate your experience with the ease of payment?'
                },
                {
                    number: 7,
                    type: 'rating',
                    type_config: {
                        format: 'numerical',
                        rate_amount: 5,
                    },
                    question: 'How would you rate your experience with accessing support?'
                },
                {
                    number: 8,
                    type: 'radio',
                    type_config: {
                        radio_options: ['0 times', '1-2 times', '3-5 times', '6-9 times', '10 or more times'],
                    },
                    question: 'During use, did you experience any instances where the app would freeze, crash, or stall long enough to be disruptive to your goals at any one given time?'
                },
                {
                    number: 9,
                    type: 'radio',
                    type_config: {
                        radio_options: ['Yes', 'No'],
                    },
                    question: 'If you wanted to provide feedback or contact a representative for troubleshooting, is it clear how to go about this?'
                },
                {
                    number: 10,
                    type: 'rating',
                    type_config: {
                        format: 'numerical',
                        rate_amount: 5,
                    },
                    question: 'How likely are you to buy and use data via STADIO Connect?'
                },
                {
                    number: 11,
                    type: 'rating',
                    type_config: {
                        format: 'numerical',
                        rate_amount: 5,
                    },
                    question: 'How likely are you to recommend STADIO Connect to a friend, co-worker, or family member?'
                },
                {
                    number: 12,
                    type: 'rating',
                    type_config: {
                        format: 'numerical',
                        rate_amount: 5,
                    },
                    question: 'How likely are you to port your number and become a recurring STADIO Connect subscriber?'
                },
                {
                    number: 13,
                    type: 'textfield',
                    conditional: false,
                    question: 'If you have any other feedback, please let us know?'
                }
            ]
        }
    ]);

    useEffect(() => {
        (async () => {
            if (survey_id) {
                await getSurvey(parseInt(survey_id));
            }
        })();
    }, []);

    const getSurvey = async (id) => {
        try {
            let survey = _.find(surveys, { 'id': parseInt(id) });
            setSteps(survey.survey.length + 1);
            setSurvey(survey.survey);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (survey_answers.length !== 0) {
            let answer = _.find(survey_answers, { 'question_number': activeStep + 1 });
            if (answer) {
                setDisabledStep(false);
            } else {
                setDisabledStep(true);
            }
        }
    }, [survey_answers, activeStep]);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleAnswer = async (answer) => {
        if (survey_answers.length !== 0) {
            let current_answer = _.find(survey_answers, { 'question_number': answer.question_number });
            if (current_answer) { _.remove(survey_answers, survey_answer => survey_answer.question_number === current_answer.question_number); }
        }
        setSurveyAnswers(survey_answers => [...survey_answers, answer]);
    };

    return (
        <>
            <Container sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }} maxWidth={'xs'}>
                {survey.length === 0 ?
                    <Box>
                        <CircularProgress color='primary' />
                    </Box>
                    :
                    <Box sx={{ width: '100%', mb: 10 }}>
                        {survey.map((question) => {

                            if (question.number == activeStep + 1) {

                                if (question.type === 'radio') {
                                    return <RadioComponent key={question.number} question={question} handleNext={handleNext} handleAnswer={handleAnswer} survey_answers={survey_answers} />;
                                }

                                if (question.type === 'rating') {
                                    return <RatingComponent key={question.number} question={question} handleNext={handleNext} handleAnswer={handleAnswer} survey_answers={survey_answers} />;
                                }

                                if (question.type === 'textfield') {
                                    return <TextFieldComponent key={question.number} question={question} handleNext={handleNext} handleAnswer={handleAnswer} survey_answers={survey_answers} />;
                                }

                                if (question.type === 'checkbox') {
                                    return <CheckboxComponent key={question.number} question={question} handleNext={handleNext} handleAnswer={handleAnswer} survey_answers={survey_answers} />;
                                }

                                if (question.type === 'done') {
                                    return <DoneComponent key={question.number} reward_value={reward_value} i_customer={parseInt(i_customer)} survey_answers={survey_answers} survey_id={parseInt(survey_id)} />;
                                }
                            }

                        })}
                    </Box>
                }
                <MobileStepper
                    color='primary'
                    variant="progress"
                    steps={steps - 1}
                    position="bottom"
                    activeStep={activeStep}
                    sx={{ maxWidth: 400, flexGrow: 1 }}
                    nextButton={
                        <Button size="small" onClick={handleNext} disabled={activeStep === steps || disabledStep}>
                            <KeyboardArrowRight />
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                            <KeyboardArrowLeft />
                        </Button>
                    }
                />
            </Container>
        </>
    );
};
export default Home;
